import React from 'react';
import PropTypes from 'prop-types';
import ProductSearch from '@components/ProductSearch';
// import { DEFAULT_FINISH_LABEL } from '../../common';
import { DEFAULT_COLLECTION_LABEL } from '../../common';


const TypePage = ({ location }) => (
  <ProductSearch
    location={location}
    defaultPath="/type"
    secondaryDefaultLabel={DEFAULT_COLLECTION_LABEL}
    advancedFiltersOpen={false}
    pageName=" | Sort by Luminaire"
  />
);

TypePage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
};

export default TypePage;
